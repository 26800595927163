.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-tree li ul {
	padding: 0;
}

.table-header {
	display: "flex";
	flex: 1;
}

.table-number {
	text-align: center;
	font-size: 1.1rem;
}

.bold {
	font-weight: bold;
}

.table-header-label {
	font-size: 0.9rem;
}

.confirmed {
	color: #444444;
}

.deaths {
	color: #fe091a;
}

.recovered {
	color: #00a84c;
}

.stats-label {
	font-size: 1.9rem;
	font-weight: bold;
}

.source-logo {
	margin-right: 6px;
	height: 24px;
	filter: grayscale(80%);
	opacity: 0.7;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.legend-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 6px;
}

.a-center {
	align-items: center;
}

.page-title {
	font-size: 1.4rem;
	font-weight: 600;
}

.tab-title {
	font-size: 0.98rem;
	font-weight: 600;
}

.location-name {
	display: "flex";
	flex: 2;
	font-size: 0.95rem;
}

.ecommerce-logo {
	flex: 1;
	display: flex;
	max-width: 65px;
	max-height: 65px;
	border: 1px solid #eee;
	border-radius: 20%;
  margin: auto;
}

.ph {
	padding-left: 8px;
	padding-right: 8px;
}

.caption {
	color: #888888;
}

.italic {
	font-style: italic;
}

.mt-1 {
	margin-top: 6px;
}

.mt-2 {
	margin-top: 8px;
}

.mt-3 {
	margin-top: 12px;
}

.mb-1 {
	margin-bottom: 6px;
}

.mb-2 {
	margin-bottom: 8px;
}

.mb-3 {
	margin-bottom: 12px;
}

.mr-1 {
	margin-right: 6px;
}

.mr-2 {
	margin-right: 8px;
}

.mr-3 {
	margin-right: 12px;
}

.ml-1 { margin-left: 6px; }

.ml-2 {
	margin-left: 8px;
}

.ml-3 {
	margin-left: 12px;
}

.pointer {
	cursor: pointer;
}

.flex {
	display: flex;
}

.f1 {
	display: flex;
	flex: 1;
}

.center {
	justify-content: center;
	align-items: center;
}

.jSb {
	justify-content: space-between;
}

.aCenter {
	align-items: center;
}

.jCenter {
	justify-content: center;
}

.notification-dot {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #fe091a;
	position: absolute;
	right: 6px;
	top: 12px;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background-color: #00a84c22;
}

.ant-tree li .ant-tree-node-content-wrapper:hover {
	background-color: #61dafb44;
}

.source-label {
	font-size: small;
}

.tab-title-container {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.tab-title-container .icon-container {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background-color: #00a84c;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.tab-title-container .label {
	line-height: 1.3;
	margin-top: 6px;
}

.tab-title-container .label-text {
	color: #f9f9f9;
	font-size: 1.5rem;
	font-weight: bold;
}

.ant-tabs-nav-animated > div {
	display: flex;
}

.ant-tabs-nav-wrap {
	/* height: 65px; */
}
/* ant-checkbox-wrapper ant-checkbox-wrapper-checked */

#chart-legend {
	font-weight: 600;
}
#chart-legend .ant-checkbox-group-item:nth-child(1) {
	color: #555;
}
#chart-legend .ant-checkbox-group-item:nth-child(3) {
	color: #ec932f;
}
#chart-legend .ant-checkbox-group-item:nth-child(2) {
	color: #0288d1;
}

#chart-legend .ant-checkbox-group-item:nth-child(1) .ant-checkbox-inner {
	border-color: #555;
}
#chart-legend
	.ant-checkbox-group-item:nth-child(1)
	.ant-checkbox-checked
	.ant-checkbox-inner {
	background-color: #555;
	border-color: #555;
}
#chart-legend .ant-checkbox-group-item:nth-child(3) .ant-checkbox-inner {
	border-color: #ec932f;
}
#chart-legend
	.ant-checkbox-group-item:nth-child(3)
	.ant-checkbox-checked
	.ant-checkbox-inner {
	background-color: #ec932f;
	border-color: #ec932f;
}
#chart-legend .ant-checkbox-group-item:nth-child(2) .ant-checkbox-inner {
	border-color: #0288d1;
}
#chart-legend
	.ant-checkbox-group-item:nth-child(2)
	.ant-checkbox-checked
	.ant-checkbox-inner {
	background-color: #0288d1;
	border-color: #0288d1;
}

#chart-legend .ant-checkbox-inner {
	border-radius: 50%;
}

.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.09;
}

.water-mark {
	color: black;
	text-align: center;
	padding-bottom: 72px;
	pointer-events: none;
}

.light-text {
	color: #999999;
}
.blinking {
	-webkit-animation: 3s blink ease infinite;
	-moz-animation: 3s blink ease infinite;
	-ms-animation: 3s blink ease infinite;
	-o-animation: 3s blink ease infinite;
	animation: 3s blink ease infinite;
}

@keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-moz-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-webkit-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-ms-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-o-keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

.dot-2 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.site-logo {
  flex: 1;
  max-width: 70px;
  max-height: 70px;
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0.3;
}

.hero-logo {
	max-height: 55px;
	padding-left: 5px;
	padding-right: 5px;
}

.ellipsis {
  text-overflow: ellipsis;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.ant-tabs-nav .ant-tabs-tab {
	margin: 0 19px 0 0;
}